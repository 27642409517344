import React from 'react'
import ImageBloc from 'components/ImageBloc/'
import Layout from 'components/layout'
import { enumTheme } from 'config'
import { getCurrentLang } from 'utils'
import { getPageTitle } from '../slugs'
import Lightbox from 'react-images'

class PhotosPage extends React.Component {
  constructor (props) {
    super(props)
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.lightboxPrev = this.lightboxPrev.bind(this)
    this.lightboxNext = this.lightboxNext.bind(this)
    this.state = {
      lightboxIsOpen: false,
      lightboxCurrentImage: 0
    }
  }

  closeLightbox () {
    this.setState({ lightboxIsOpen: false })
  }

  openLightbox (index = 0) {
    this.setState({ lightboxIsOpen: true, lightboxCurrentImage: index })
  }

  lightboxNext () {
    const { lightboxCurrentImage } = this.state
    this.setState({ lightboxCurrentImage: lightboxCurrentImage + 1 })
  }

  lightboxPrev () {
    const { lightboxCurrentImage } = this.state
    this.setState({ lightboxCurrentImage: lightboxCurrentImage - 1 })
  }

  render () {
    const {
      data: {
        illustrationsColonne1,
        illustrationsColonne2,
        illustrationsColonne3
      },
      slugs,
      lang
    } = this.props

    let lightboxImages = illustrationsColonne1.concat(illustrationsColonne2, illustrationsColonne3)
    lightboxImages = lightboxImages.map(image => { return { src: image.fluid.src, srcSet: image.fluid.srcSet } })

    return (
      <Layout
        slugs={slugs}
        lang={lang}
        pageTitle={getPageTitle('photos', lang)}
        headerScroll={0}
        footerTheme={enumTheme.WHITE}
        socialMediaIsDark={false}
        socialMediaScroll={0}
        socialMediaOffset={0}
        baseUrl={getCurrentLang(this.props.location.pathname)}
        currentPage={this.props.currentPage}>
        <div className="grid">
          <ImageBloc imgs={illustrationsColonne1} onImageClick={this.openLightbox} lightBoxIndexStart={0}/>
          <ImageBloc imgs={illustrationsColonne2} onImageClick={this.openLightbox} lightBoxIndexStart={illustrationsColonne1.length}/>
          <ImageBloc imgs={illustrationsColonne3} onImageClick={this.openLightbox} lightBoxIndexStart={illustrationsColonne1.concat(illustrationsColonne2).length}/>
        </div>
        <Lightbox
          backdropClosesModal={true}
          images={lightboxImages}
          isOpen={this.state.lightboxIsOpen}
          onClose={this.closeLightbox}
          onClickPrev={this.lightboxPrev}
          onClickNext={this.lightboxNext}
          width={1800}
          currentImage={this.state.lightboxCurrentImage}/>
      </Layout>
    )
  }
};

export default PhotosPage
