import React from 'react'
import Photos from 'templates/photos'
import { graphql } from 'gatsby'

export default props => {
  const {
    data: {
      allDatoCmsPhoto
    },
    pageContext: {
      lang,
      slugs
    }
  } = props

  const data = allDatoCmsPhoto && allDatoCmsPhoto.edges[0].node

  return (
    <div>
      {data &&
        <Photos
          data={data}
          location={{ pathname: '/' }}
          currentPage={ props.location.pathname }
          lang={lang}
          slugs={slugs}
        />
      }
    </div>
  )
}

export const query = graphql`
  query PhotosQuery($website: String!) {
    allDatoCmsPhoto(filter: {website: {eq: $website}}) {
      edges {
        node {
          illustrationsColonne1 {
            fluid(maxWidth: 1800, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
          illustrationsColonne2 {
            fluid(maxWidth: 1800, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
          illustrationsColonne3 {
            fluid(maxWidth: 1800, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`
